@font-face {
font-family: '__font_f34ea6';
src: url(/_next/static/media/703c4186f681cdb1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__font_f34ea6';
src: url(/_next/static/media/94fd3198419989ac-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__font_f34ea6';
src: url(/_next/static/media/71e1265be947b594-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__font_Fallback_f34ea6';src: local("Arial");ascent-override: 85.72%;descent-override: 22.28%;line-gap-override: 0.00%;size-adjust: 102.99%
}.__className_f34ea6 {font-family: '__font_f34ea6', '__font_Fallback_f34ea6'
}.__variable_f34ea6 {--font-museo: '__font_f34ea6', '__font_Fallback_f34ea6'
}

@font-face {
font-family: '__scribbles_eb748c';
src: url(/_next/static/media/8bc361eeb032a25f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__scribbles_Fallback_eb748c';src: local("Arial");ascent-override: 98.43%;descent-override: 27.04%;line-gap-override: 0.00%;size-adjust: 81.25%
}.__className_eb748c {font-family: '__scribbles_eb748c', '__scribbles_Fallback_eb748c';font-weight: 400;font-style: normal
}.__variable_eb748c {--font-scribbles: '__scribbles_eb748c', '__scribbles_Fallback_eb748c'
}

/* latin-ext */
@font-face {
  font-family: '__Luckiest_Guy_e61f6e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/643cfb2a6fb11ec2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Luckiest_Guy_e61f6e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/844dc71011366724-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Luckiest_Guy_Fallback_e61f6e';src: local("Arial");ascent-override: 61.73%;descent-override: 26.06%;line-gap-override: 0.00%;size-adjust: 113.91%
}.__className_e61f6e {font-family: '__Luckiest_Guy_e61f6e', '__Luckiest_Guy_Fallback_e61f6e';font-weight: 400;font-style: normal
}.__variable_e61f6e {--font-glossary: '__Luckiest_Guy_e61f6e', '__Luckiest_Guy_Fallback_e61f6e'
}

